









import { Component, Vue } from 'vue-property-decorator';
import TrackingHelper from '@/shared/helpers/tracking';
import { namespace } from 'vuex-class';

const SchoolModule = namespace('school');

@Component({
  components: {},
})
export default class EmptyPage extends Vue {
  @SchoolModule.State selectedSchool!: any;

  created() {
    this.trackPageViewed();
  }

  trackPageViewed() {
    switch (this.$route.name) {
      case 'CommunityPage':
        TrackingHelper.trackCommunityPageViewed();
        break;

      case 'FaqPage':
        TrackingHelper.trackFAQPageViewed();
        break;
    }
  }
}
